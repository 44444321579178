<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="邮件设置"/>
        <div style="margin-top: 20px;max-width: 600px;">
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="证书">
                    <el-select v-model="form.cid" placeholder="请选择证书">
                        <el-option :label="item.title" :value="item.id" v-for="(item, idx) in certs" :key="idx"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="邮件标题">
                    <el-input v-model="form.mail_title"></el-input>
                </el-form-item>
                <el-form-item label="邮件内容">
                    <el-input type="textarea" rows="10" v-model="form.mail_body"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit">确 定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                loading: false,
                certs: [],
                form: {
                    cid: 4,
                    mail_title: '',
                    mail_body: '',
                },
                rules: {
                    mail_title: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            async submit() {
                const resp = await this.$http.post(`/setting/mail`, this.form)
                if (resp.data.state == 200) {
                    this.$message.success("保存成功")
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async getData() {
                const cert = await this.$http.get('/cert/?page=1&pageSize=1000')
                this.certs = cert.data.data.content
                const resp = await this.$http.get(`/setting/mail/${this.$route.query.cid}`)
                this.form = resp.data.data
                if (this.$route.query.cid) {
                    this.form.cid = parseInt(this.$route.query.cid)
                }
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        padding-bottom: 40px;
        text-align: center;
    }

</style>
